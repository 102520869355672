<template>
    <CForm ref="form" class="needs-validation"   >
        <CRow>
            <CCol>
                <CInput
                label="İsim: "
                horizontal
                type="text"
                autocomplete="text"
                v-model="data.name"
                />
            </CCol>
            <CCol>
                <CInput
                label="Kod: "
                horizontal
                type="text"
                autocomplete="text"
                v-model="data.code"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CInput
                label="Model: "
                horizontal
                type="text"
                autocomplete="text"
                v-model="data.model"
                />
            </CCol>
            <CCol>
                <CInput
                label="Raf Kodu: "
                horizontal
                type="text"
                autocomplete="text"
                v-model="data.warehouseLocation"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CSelect
                    label="Tasarımcı: "
                    placeholder="Seçiniz.."
                    horizontal
                    :options="activeDesigners"
                    :value.sync="data.productDesigner"
                />
            </CCol>
            <CCol>
                <CSelect
                    label="Ürün Tipi: "
                    placeholder="Seçiniz.."
                    horizontal
                    :options="activeProductTypesWoS"
                    :value.sync="data.productType"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol col="3">
                <CRow form class="form-group">
                    <CCol tag="label" sm="6" class="col-form-label">
                        <b>Aktif</b>
                    </CCol>
                    <CCol>
                        <CSwitch
                            class="mr-1"
                            size="lg"
                            color="primary"
                            :checked.sync="data.activeFlag"
                        />
                    </CCol>
                </CRow>
            </CCol>
            <CCol col="3">
                <CRow form class="form-group">
                    <CCol tag="label" sm="6" class="col-form-label">
                        <b>Yeni</b>
                    </CCol>
                    <CCol>
                        <CSwitch
                            class="mr-1"
                            size="lg"
                            color="primary"
                            :checked.sync="data.newFlag"
                        />
                    </CCol>
                </CRow>
            </CCol>
            <CCol col="3">
                <CRow form class="form-group">
                    <CCol tag="label" sm="6" class="col-form-label">
                        <b>Clerance</b>
                    </CCol>
                    <CCol>
                        <CSwitch
                            class="mr-1"
                            size="lg"
                            color="primary"
                            :checked.sync="data.clearanceFlag"
                        />
                    </CCol>
                </CRow>
            </CCol>
            <CCol col="3">
                <CRow form class="form-group">
                    <CCol tag="label" sm="6" class="col-form-label">
                        <b>Catalog Feed</b>
                    </CCol>
                    <CCol>
                        <CSwitch
                            class="mr-1"
                            size="lg"
                            color="primary"
                            :checked.sync="data.catalogFeedFlag"
                        />
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CSelect
                    label="Ürün Grubu: "
                    placeholder="Seçiniz.."
                    horizontal
                    :options="activeProductGroups"
                    :value.sync="data.productGroup"
                    :disabled="actionType !== 'create'"
                />
            </CCol>
            <CCol>
                <CInput
                    label="Üretici: "
                    horizontal
                    type="text"
                    autocomplete="text"
                    v-model="data.manufacturer"
                    :disabled="actionType !== 'create'"
                />
            </CCol>
        </CRow>
        <CRow>
        <CCol>
            <CSelect
                label="Uzunluk Birimi: "
                placeholder="Seçiniz.."
                horizontal
                :options="dimensionUOMNames"
                :value.sync="data.dimensionUOM"
                :disabled="actionType !== 'create'"
            />
        </CCol>
        <CCol>
            <CInput
                label="Genişlik: "
                horizontal
                type="number"
                v-model="data.width"
                :disabled="actionType !== 'create'"
            />
        </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CInput
                    label="Yükseklik: "
                    horizontal
                    type="number"
                    v-model="data.height"
                    :disabled="actionType !== 'create'"
                />
            </CCol>
            <CCol>
                <CInput
                    label="Derinlik: "
                    horizontal
                    type="number"
                    v-model="data.depth"
                    :disabled="actionType !== 'create'"
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CSelect
                    label="Ağırlık Birim: "
                    placeholder="Seçiniz.."
                    horizontal
                    :options="weightUOMNames"
                    :value.sync="data.weightUOM"
                    :disabled="actionType !== 'create'"
                />
            </CCol>
            <CCol>
                <CInput
                    label="Ağırlık: "
                    horizontal
                    autocomplete="text"
                    v-model="data.weight"
                    :disabled="actionType !== 'create'"
                />
            </CCol>
        </CRow>
   </CForm>
</template>

<script>
   export default{
       name: "ProductForm",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
           actionType:  function(val){
               if(val == 'create')
                   this.data = {}
           }
       },
       computed:{
            activeProductTypesWoS: function() {
                let data = []
                this.$store.getters.activeProductTypesWoS.map(r => data.push({value:r.id, label: r.name}))
                return data
            },
            activeProductGroups: function(){
                let data = []
                this.$store.getters.activeProductGroups.map(r => data.push({value:r.id, label: r.name}))
                return data
            },
            activeDesigners: function(){
                let data = []
                this.$store.getters.activeDesigners.map(r => data.push({value:r.id, label: r.name}))
                return data
            },
       },
       data: function(){
           return{
                data: this.params ? {...this.params} : {},
                weightUOMNames: ["Pounds", "Kilograms"],
                dimensionUOMNames: ["Centimeters", "Meters", "Inches", "Feet"],
           }
       },
   }
</script>